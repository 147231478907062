
import { defineComponent } from "@vue/runtime-core";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import Button from "@/components/Button.vue";

import axios from "axios";
import moment from "moment-timezone";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

interface IDataModel {
  loading: boolean;
  teasersMaxId: number | null;
  options: {
    categories: Array<Option>;
    geos: Array<Option>;
    offers: Array<Option>;
  };
  errors: { [k: string]: string | null };
  image: any;
  isCropperExist: boolean;
  images: any;
  tab: number | null;
}

interface Option {
  id: number;
  name: string;
}

export default defineComponent({
  components: {
    Popup,
    TextInput,
    Dropdown,
    Button,
    VueCropper,
  },
  props: {
    teaser: Object,
  },
  data(): IDataModel {
    return {
      loading: false,
      teasersMaxId: null,
      options: {
        categories: [],
        offers: [],
        geos: [],
      },
      errors: {} as { [key: string]: string | null },
      image: null,
      isCropperExist: false,
      images: {
        square: null,
        rectangle1: null,
        rectangle2: null,
      },
      tab: 0,
    };
  },
  emits: ["close", "update:teaser", "saved", "period"],
  async created() {
    this.searchData("", "geos");
    this.searchData("", "offers");
    axios
      .get("/api/teasers/get-max-id")
      .then((res) => (this.teasersMaxId = res.data["max"]));
    axios
      .get("/api/teasers-subcategories/lookup")
      .then((res) => (this.options.categories = res.data));
  },
  computed: {
    updatedAtDate() {
      return moment(new Date()).format("DD/MM/YYYY");
    },
    currentTeaserId(): number {
      return Number(this.teasersMaxId) + 1;
    },
  },
  methods: {
    cancel() {
      this.tab = 0;
      this.isCropperExist = false;
      this.image = null;
      this.$emit("update:teaser", {
        ...this.teaser,
        image_filename: null,
      });
    },
    async setImage(e: any) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target?.result;
          this.isCropperExist = true;
          this.tab = 0;
          this.$emit("update:teaser", {
            ...this.teaser,
            image_filename: this.imageToBlob(this.image),
          });
          // rebuild cropperjs with the updated source
          // this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.errors.image_filename = null;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    next() {
      const cropper = this.$refs.cropper as any;

      if (this.tab === 0) {
        this.images.square = cropper.getCroppedCanvas().toDataURL();
        cropper.setAspectRatio(1.75);
      }

      if (this.tab === 1) {
        this.images.rectangle1 = cropper.getCroppedCanvas().toDataURL();
        cropper.setAspectRatio(0.82);
      }

      if (this.tab === 2) {
        this.cropImage();
      }

      if (this.tab !== 2) {
        this.tab = (this.tab || 0) + 1;
      }
    },
    async imageToBlob(image: any) {
      return image && (await (await fetch(image)).blob());
    },
    async cropImage() {
      const cropper = this.$refs.cropper as any;

      if (this.tab === 2) {
        this.images.rectangle2 = cropper.getCroppedCanvas().toDataURL();
      }

      this.images.square =
        this.images.square && (await this.imageToBlob(this.images.square));
      this.images.rectangle1 =
        this.images.rectangle1 &&
        (await this.imageToBlob(this.images.rectangle1));
      this.images.rectangle2 =
        this.images.rectangle2 &&
        (await this.imageToBlob(this.images.rectangle2));

      this.isCropperExist = false;
    },
    formatDate(date: string) {
      return moment(date).format("DD/MM/YYYY");
    },
    close() {
      this.$emit("close");
    },
    async emitPatch(patch: any) {
      this.$emit("update:teaser", {
        ...this.teaser,
        ...patch,
      });

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }

      if (patch.geo_id) {
        const { data } = await axios.get("/api/offers/lookup", {
          params: {
            q: JSON.stringify({
              geo_id: patch.geo_id,
            }),
          },
        });

        this.options.offers = data;
      }
    },
    async searchTeasersSubcategories(query: string) {
      const { data } = await axios.get(`/api/teasers-subcategories/lookup`, {
        params: {
          q: JSON.stringify({
            query,
          }),
        },
      });

      this.options.categories = data;
    },
    async searchData(query: string, collection: string) {
      const { data } = await axios.get(`/api/${collection}/lookup`, {
        params: {
          q: JSON.stringify({
            query,
            geo_id: this.teaser?.geo_id,
          }),
        },
      });

      const key: "geos" | "offers" = collection as any;
      this.options[key] = data;
    },
    async saveTeaser() {
      this.loading = true;
      let formData = new FormData();

      for (const name in this.images) {
        formData.append(name, this.images[name]);
      }

      for (const name in this.teaser) {
        formData.append(name, this.teaser[name]);
      }

      formData.append("image_filename", await this.imageToBlob(this.image));

      try {
        await axios.post("/api/teasers", formData);
        this.$emit("saved");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }

      this.loading = false;
    },
  },
});
