<template>
  <div :class="['category-tag', styles[category]]">
    {{ category }}
  </div>
</template>

<script>
export default {
  props: {
    category: String,
  },
  data() {
    return {
      styles: {
        "Шоу-бизнес": "show-business",
        События: "events",
        Политика: "politics",
        Здоровье: "health",
        Спорт: "sport",
        Кухня: "cuisine",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.category-tag {
  display: block;
  padding: 8px 16px;
  border-radius: 4px;
  width: max-content;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  background: #d8e5ff;

  &.show-business {
    background: fade-out(#b870f0, 0.8);
    color: #b870f0;
  }

  &.health {
    color: #55d740;
    background: fade-out(#55d740, 0.8);
  }

  &.sport {
    color: #ffa800;
    background: fade-out(#ffa800, 0.8);
  }

  &.politics {
    color: #78a6ff;
    background: fade-out(#78a6ff, 0.8);
  }

  &.events {
    color: #f07070;
    background: fade-out(#f07070, 0.8);
  }

  &.cuisine {
    color: #0d95e8;
    background: fade-out(#0d95e8, 0.8);
  }
}
</style>
