
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import Button from "@/components/Button.vue";
import DataTable from "@/components/DataTable.vue";
import TextInput from "@/components/TextInput.vue";
import Daterange from "@/components/Daterange.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import Category from "@/components/Category.vue";
import Tooltip from "@/components/Tooltip.vue";
import Editor from "./Editor.vue";
import Unpublish from "./Unpublish.vue";
import Publish from "./Publish.vue";
import TeaserTemplatesInfo from "./TeaserTemplatesInfo.vue";

import { Option, Pagination, Sorting } from "@/use/types";
import moment, { Moment } from "moment-timezone";
import axios from "axios";
import { mapState } from "vuex";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  domains: any[];
  totalRows: number;
  columns: Column[];
  options: {
    teasersSubcategories: Option[];
    geos: Option[];
    offers: Option[];
  };
  definition: any;
  actionsVisibleIndex: number | null;
  isUnpublishPopupShowed: boolean;
  unpublishTeaserId: number | null;
  teaserToEdit: null;
  isPublishPopupShowed: boolean;
  publishTeaserId: number | null;
  errors: any;
  uploading: boolean;
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  category_ids: number[];
  geo_ids: number[];
  not_published: boolean;
  offer_ids: number[];
  non_image: boolean;
  templates_info: boolean;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [],
    search: "",
    sorting: { column: "updated_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    category_ids: [],
    geo_ids: [],
    not_published: false,
    offer_ids: [],
    non_image: false,
    templates_info: false,
  };
}

const dataColumns = [
  {
    id: "updated_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "id",
    name: "ID",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "image_filename",
    name: "Изображение",
    selected: true,
    sortable: false,
    htmlClass: "teaser__table--image",
  },
  {
    id: "title",
    name: "Заголовок",
    selected: true,
    sortable: true,
    htmlClass: "teaser__table--h",
    type: "text",
    searchable: true,
  },
  {
    id: "category_name",
    name: "Категория",
    selected: true,
    sortable: true,
    htmlClass: "teaser__table--mark",
    type: "text",
  },
  {
    id: "offer",
    name: "Оффер",
    selected: true,
    sortable: true,
    htmlClass: "teaser__table--offer",
    type: "text",
  },
  {
    id: "advertiser_name",
    name: "Рекл.",
    selected: true,
    sortable: true,
    htmlClass: "teaser__table--advertisers",
    type: "text",
  },
  {
    id: "geo",
    name: "Гео",
    selected: true,
    sortable: true,
    htmlClass: "teaser__table--geo",
  },
  {
    id: "weight",
    name: "Вес",
    selected: true,
    sortable: true,
    htmlClass: "teaser__table--weight",
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
    htmlClass: "teaser__table--action",
  },
];

export default defineComponent({
  components: {
    AppPage,
    Button,
    DataTable,
    SubsetInput,
    TextInput,
    Tooltip,
    Category,
    Daterange,
    Editor,
    Unpublish,
    Publish,
    TeaserTemplatesInfo
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      domains: [],
      totalRows: 0,
      columns: dataColumns,
      options: {
        teasersSubcategories: [],
        geos: [],
        offers: [],
      },
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      actionsVisibleIndex: null,
      isUnpublishPopupShowed: false,
      unpublishTeaserId: null,
      teaserToEdit: null,
      isPublishPopupShowed: false,
      publishTeaserId: null,
      errors: null,
      uploading: false,
    };
  },
  async created() {
    this.load();
    this.getCategories();
    this.getGeos();
    this.getOffers();
    await this.getPeriodLimits();
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted: function () {
    this.$watch("filter.not_published", this.load);
    this.$watch("filter.non_image", this.load);
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.templates_info", this.load);
  },
  methods: {
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get(
        "/api/" + (field || collection) + "/lookup",
        {
          params: { q: JSON.stringify({ query }) },
        }
      );
      const key: "teasersSubcategories" | "geos" | "offers" = collection as any;
      this.options[key] = data;
    },
    async readSingleFile(evt: any) {
      let f = evt.target.files[0];
      this.uploading = true;
      this.errors = null;
      let contents;
      if (f.name.toLowerCase().lastIndexOf(".csv") == -1) {
        alert("Please upload only CSV files");
        return;
      }
      if (f) {
        let r = new FileReader();
        r.onload = function (e) {
          contents = e.target?.result;
        };
        r.readAsText(f);

        let formData = new FormData();
        formData.append("teasers", f);
        try {
          await axios.post("/api/teasers/upload", formData);
          (this.$refs.teasers_file as HTMLInputElement).value = "";
          this.uploading = false;
          this.load();
        } catch (e) {
          if (e.response.status === 500) {
            this.errors = e.response.data.errors;
          }
        }
      }
    },
    async getPeriodLimits() {
      const { data } = await axios("/api/get-period-limits/teasers");
      this.filter.period = [
        data.min || moment().endOf("day"),
        moment().endOf("day"),
      ];
    },
    setActionsVisible(id: number | null) {
      if (this.actionsVisibleIndex === id) {
        this.actionsVisibleIndex = null;
      } else {
        this.actionsVisibleIndex = id;
      }
    },
    unpublishTeaser(id: number) {
      this.isUnpublishPopupShowed = true;
      this.unpublishTeaserId = id;
      this.actionsVisibleIndex = null;
    },
    publishTeaser(id: number) {
      this.isPublishPopupShowed = true;
      this.publishTeaserId = id;
      this.actionsVisibleIndex = null;
    },
    async closePopup() {
      await this.getPeriodLimits();

      if (this.isUnpublishPopupShowed) {
        this.isUnpublishPopupShowed = false;
        this.unpublishTeaserId = null;
      }

      if (this.isPublishPopupShowed) {
        this.isPublishPopupShowed = false;
        this.publishTeaserId = null;
      }

      if (this.teaserToEdit) {
        this.teaserToEdit = null;
        document.querySelector("body")?.classList.remove("editor-opened");
      }

      this.load();
    },
    getGeoIdByIsoCode(iso_code: string): number {
      return this.options.geos.find((x: any) => x.iso_code === iso_code)
        ?.id as number;
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    async getCategories() {
      const { data } = await axios.get("/api/teasers-subcategories/lookup");
      this.options.teasersSubcategories = data;
    },
    async getGeos() {
      const { data } = await axios.get("/api/geos/lookup-for-news");
      this.options.geos = data;
    },
    async getOffers() {
      const { data } = await axios.get("/api/offers/lookup");
      this.options.offers = data;
    },
    updateCategoriesAndLoad(e: any) {
      this.filter.category_ids = e;
    },
    updateGeosAndLoad(e: any) {
      this.filter.geo_ids = e;
      ``;
    },
    updateOffersAndLoad(e: any) {
      this.filter.offer_ids = e;
    },
    async clear() {
      this.filter = getDefaultQuery();
      await this.getPeriodLimits();
      this.load();
    },
    async setPeriod(value: any) {
      if (!value.length) {
        await this.getPeriodLimits();
        return;
      }
      this.filter.period = value;
    },
    async load() {
      this.loading = true;
      this.uploading = false;
      this.rows = [];
      this.totalRows = 0;
      if (this.$refs.teasers_file) {
        (this.$refs.teasers_file as HTMLInputElement).value = "";
      }

      try {
        const [{ data: teasers }, { data: domains }] = await Promise.all([
          axios.get("/api/teasers", {
            params: { q: JSON.stringify(this.filter) },
          }),
          axios.get("/api/domains"),
        ]);
        this.rows = teasers.rows;
        this.totalRows = teasers.total;
        this.domains = domains;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    toNotPublished() {
      this.filter.not_published = true;
      this.filter.non_image = false;
      this.filter.templates_info = false;
    },
    toActive() {
      this.filter.not_published = false;
      this.filter.non_image = false;
      this.filter.templates_info = false;
    },
    toNonImage() {
      this.filter.not_published = false;
      this.filter.templates_info = false;
      this.filter.non_image = true;
    },
    toTemplatesInfo() {
      this.filter.not_published = false;
      this.filter.non_image = false;
      this.filter.non_image = false;
      this.filter.templates_info = true;
    },
    download() {
      window.open(
        `/api/news/download?q=${encodeURIComponent(
          JSON.stringify(this.filter)
        )}`,
        "_blank"
      );
    },
    openEditor(row: any) {
      document.querySelector("body")?.classList.add("editor-opened");

      this.teaserToEdit = row && {
        ...row,
        weight: row && row.weight ? row.weight : Number(0).toFixed(6),
      } || {
        title: "",
        landing: "",
        category_id: null,
        transit: "",
        offer_id: null,
        weight: 0,
        is_published: true,
        geo_id: null,
        image_filename: null,
        square_img_crop: null,
        rectangle1_img_crop: null,
        rectangle2_img_crop: null,
        square_img_path: null,
        rectangle1_img_path: null,
        rectangle2_img_path: null,
      };

      if (this.actionsVisibleIndex !== null) this.actionsVisibleIndex = null;
    },
  },
});
