<template>
  <div class="teasers-template-info">
    <div class="teasers-template-info__top">
      <TextInput
        label="Кол-во инлайнов на странице"
        v-model:value="inlineTeaserCount"
        :placeholder="inlineTeaserCount.toString()"
      />
      <Button
        text="Рассчитать"
        className="btn-secondary"
        class="_filled"
        @click="calculate"
      />
    </div>
    <table>
      <thead>
        <tr>
          <th v-for="c of columns" :key="c.id">
            <div :class="['th__inner', c.htmlClass]">
              {{ c.name }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="r of rows"
          :key="r[columns[0].id]"
          :class="[
            { warning: r.teasers_blocks_count >= 2 && r.teasers_blocks_count <= 4 },
            { danger: r.teasers_blocks_count === 0 || r.teasers_blocks_count === 1 }
          ]"
        >
          <td v-for="c of columns" :key="c.id">
            <slot :name="`row.${c.id}`" :row="r" :column="c">
              {{ r[c.id] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput";
import Button from "@/components/Button";
import axios from "axios";

const columns = [
  {
    id: "geo",
    name: "Гео",
  },
  {
    id: "all_teasers",
    name: "Всего",
  },
  {
    id: "sidebar_teasers_amount",
    name: "Для сайбара",
  },
  {
    id: "teasers_blocks_count",
    name: "Кол-во блоков",
  },
];

export default {
  components: {
    TextInput,
    Button,
  },
  data() {
    return {
      columns: columns,
      rows: [],
      inlineTeaserCount: 3,
    };
  },
  created() {
    this.load();
  },
  methods: {
    calculate() {
      this.load();
    },
    async load() {
      const { data } = await axios.get("/api/teasers/get-templates-info", {
        params: {
          inlineTeaserCount: this.inlineTeaserCount,
        },
      });
      this.rows = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.teasers-template-info {
  padding: 20px 0;

  &__top {
    display: flex;
    gap: 10px 15px;
    flex-wrap: wrap;

    button {
      align-self: flex-end;
    }
  }
}

table {
  border-spacing: 0 8px;
  border-collapse: separate;

  th {
    font-weight: 500;
  }

  tr {
    &.danger {
      box-shadow: 0 0 3px red;
    }

    &.warning {
      box-shadow: 0 0 3px #d58401;
    }
  }

  th,
  td {
    padding: 15px 10px;
    background-color: #fff;

    &:first-child {
      text-align: start;
    }

    &:not(:first-child) {
      text-align: center;
    }
  }
}
</style>
